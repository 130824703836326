body {
    margin: 0;
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    font-size: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2C3439;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
h1,
h2,
h3,
h4 {
    font-weight: bold;
    color: #2C3439;
}
h2,
.h2 {
    font-size: 1.6rem;
}

.form-control:disabled {
    background-color: #E5EBED;
    color: #707579 ;
}

.form-control[readonly] {
    background-color: #E5EBED;
    color: #707579;
}

.center {
    margin: auto !important;
    text-align: center;
}
