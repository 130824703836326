.summaryApp {
  width: 100%;
  background-color: #e5ebed;
  padding: 5px 0px;
}

.centerContent {
  text-align: center;
  position: absolute !important;
  top: 50%;
}

.buttonContainer {
  display: flex;
  align-items: flex-end;
}

.unitBaseFieldInput{
  padding-left: 15px;
  padding-right: 15px;

  input {
    background-color: white !important;
    text-align: left !important;
    padding: 6px 12px;
    color: #555 !important;
  }
  span {
    font-weight: bold;
    color: #655655;
  };

  svg {
    color: #00a433 !important;
    fill: #00a433 !important;
    border: #00a433 !important;
  }

  :disabled {
    background-color: #eee !important;
    cursor: not-allowed !important;
  }
}

.unitBaseFieldButton {
  text-align: center;
  margin-top: 15px;
  margin-right: 15px;
  margin-left: 10px;
  padding: 5px 20px !important;
  font-size: 14px !important;
}

.viewRulesButton {
  text-align: center;
  margin-top: 0px;
  margin-right: 15px;
  margin-left: 10px;
  padding: 5px 20px !important;
  font-size: 14px !important;
}

.warningText {
  padding-bottom: 5px;
  padding-left: 5px;
  font-size: 12px;
  display: table;
}

.textCenter {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.customRow {
  margin-bottom: 20px;
}

.labelInput {
  font-size: small;
  font-weight: bold;
  color: #655655;
  padding-left: 15px;
}
