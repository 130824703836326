.summaryApp {
  width: 100%;
  background-color: #e5ebed;
}

.buttonContainer {
  text-align: right;
}

.modalButton {
  padding: 5px;
}

.modalText {
  text-align: center;
  font-size: 16px;
  color: #555;
  padding-top: 15px;
}

.unitBaseFieldClearModalButton {
  text-align: center;
  margin-top: 15px;
  margin-right: 15px;
  margin-left: 10px;
  padding: 5px 20px !important;
  font-size: 14px !important;
}

